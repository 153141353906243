export const Translate_ENG = {
	"Inicio":"Home",
	"Producciones":"Productions",
	"Finanzas":"Finances",
	"Admin":"Admin",
	"Todos los derechos reservados.":"All rights reserved.",
	"Crear Producción": 'Create Production',
	"Editar Producción": 'Edit Production',
	"Nombre":"Name",
	"Temporada":"Season",
	"Formato":"Format",
	"Género":"Genre",
	"Capítulos":"Chapters",
	"Pág x Cap":"Pg x Ch",
	"Esc x Cap":"Sce x Ch",
	"Min x Cap":"Min x Ch",
	"Unidades":"Units",
	"Sem. Pre-prod":"Wks. Pre-prod",
	"Sem. prod":"Wks. prod",
	"Sem. post":"Wks. post",
	"Incio pre-producción":"Pre-production start",
	"Incio producción":"Production start",
	"Fin produccción":"Production end",
	"Fin post-produccción":"Post-production end",
	"Cancelar":"Cancel",
	"Crear":"Create",
	"S":"W",
	"A":"TO",
	"PRE - PRODUCCIÓN:":"PRE - PRODUCTION:",
	"PRODUCCIÓN:":"PRODUCTION:",
	"POST - PRODUCCIÓN:":"POST - PRODUCTION:",
	"AIRE":"ON AIR",
	"Eliminar":"Remove",
	"Fecha inicio":"Start date",
	"Fecha fin":"End date",
	"Unidad":"Unit",
	"Filtrar":"Filter",
	"Filtrar Producción":"Filter Production",
	"Estado":"Status",
	"Incio pre-producción desde":"Pre-production start from",
	"Incio pre-producción hasta":"Pre-production start to",
	"Incio producción desde":"Production start from",
	"Incio producción hasta":"Production start to",
	"Incio post-producción desde":"Post-production start from",
	"Incio post-producción hasta":"Post-production start to",
	"Editar":"Edit",
	"Guardar":"Save",
	"Filtrar Escenas": "Filter Scenes",
	"Insertar":"Insert",
	"Capítulo desde":"Chapter from",
	"Capítulo hasta":"Chapter to",
	"Día cont desde":"Cont. day from",
	"Día cont hasta":"Cont. day to",
	"Locación / Estudio":"Location / Studio",
	"Interior / Exterior":"Inside / Outside",
	"Día / Noche":"Day / Night",
	"Fecha desde":"Date from",
	"Fecha hasta":"Date to",
	"Locaciones":"Locations",
	"Sets":"Sets",
	"Personajes":"Characters",
	"Solo personajes seleccionados":"Only selected characters",
	"Por lo menos alguno de los personajes seleccionados":"At least one of the selected characters",
	"Flashback":"Flashback",
	"Tomas de ubicación":"Location shots",
	"Hijuelas":"Added scenes",
	"(Selección múltiple con Ctrl o Cmd)":"(Multiple selection with Ctrl or Cmd)",
	"Seleccione":"Select",
	"Categoría elementos":"Item Category",
	"Elementos":"Elements",
	"Escenas":"Scenes",
	"Capítulo":"Chapter",
	"Escena":"Scene",
	"Día":"Day",
	"Año":"Year",
	"Páginas":"Pages",
	"Min. estimados":"Estimated. Min",
	"Min. producidos":"Produced. Min",
	"Min. no efectivos":"Non-effective. Min",
	"Locación":"Location",
	"Pers. principal":"Main character",
	"Pers. secundario":"Secondary character",
	"Pers. extra":"Extra character",
	"Pers. doble":"Dub character",
	"Plan diario":"Daily Plan",
	"Reportes":"Reports",
	"Dashboard":"Dashboard",
	"Plan Producción":"Production Plan",
	"Calendario Producción":"Production Calendar",
	"Hitos Producción":"Production Milestones",
	"No muestra resultados":"It does not show results",
	"Crear Escenas":"Create Scenes",
	"Editar Escenas":"Edit Scenes",
	"Número escena":"Scene number",
	"Historia del día":"Day history",
	"Página":"Page",
	"#":"#",
	"Set":"Set",
	"No hay opciones para listar":"There are no options to list",
	"Siguiente":"Next",
	"Anterior":"Previous",
	"Hijuela":"Added scene",
	"Toma de ubicación":"Establishing shoot",
	"Producida manual":"Produced",
	"Descripción":"Description",
	"Insertar texto":"Insert text",
	"Evento":"Event",
	"Eventos":"Events",
	"Nulo":"Null",
	"Mini evento":"Mini event",
	"Papel del personaje":"Character role",
	"Personaje":"Character",
	"Cant":"Qty",
	"Cantidad":"Quantity",
	"Clic para agregar un nuevo personaje":"Click to add new character",
	"Editar Personaje":"Edit Character",
	"Crear Personaje": "Add Character",
	"Nombre del personaje":"Character's name",
	"Estados escenas": "Scenes status",
	"Incluir escenas en desarrollo": "Include scenes in development",
	"Escenas en desarrollo incluidas":"Scenes in development included",
  "Incluir crew":"Include crew",
}



