import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Response } from "@angular/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import { GlobalService } from "src/app/shared/services/global.service";
import { DailyPlanStatus } from "src/app/shared/config/daily_plan_statuses";
import { DailyPlanSections } from "src/app/shared/config/daily_plan_sections";
import { FilterService } from "src/app/shared/services/filter.service";
import { DatePipe } from "@angular/common";

@Injectable({
	providedIn: "root",
})
export class ProductionServiceService {
	public produccion: any;
	public _shouldReloadRequestsDailyPlan = new BehaviorSubject<boolean>(true);
	public dailyPlanPublishedSections: any;
	private _dailyPlanUnitId: number;
	private _dailyPlanStatusId: number;
	private _dailyPlanWeekStatus: any;
	public $statusProductionEdit = new BehaviorSubject<boolean>(true);

	getMaxMinNumberChapter(chapters): any {
		if (!chapters) return { 'max': 100, 'min': 1 };

		let max = Math.max(...chapters.map(c => c.number_chapter_cast));
		let min = Math.min(...chapters.map(c => c.number_chapter_cast));
		return { 'max': max, 'min': min }
	}

	setArrayProdUnits(prod_unit_list): Array<any> {
		let numberOfUnits = this.produccion ? this.produccion.prod_units.length : 6;
		let unitLists = new Array(numberOfUnits);
		for (let i = 0; i < unitLists.length; i++) {
			unitLists[i] = prod_unit_list ? (prod_unit_list[i] ? prod_unit_list[i] : null) : null;
		}
		return unitLists;
	}

	getDatosSelectores() {
		return this.http.post(environment.apiUrl + "/production/get_data", {}).pipe(map((response: Response) => response));
	}

	listProducciones() {
		return this.http.post(environment.apiUrl + "/production/list_productions", {}).pipe(map((response: Response) => response));
	}

	addImageToProject(img, production_id) {
		let formData = new FormData();
		formData.append('id', production_id);
		formData.append('prod_image', img);
		return this.http.post(environment.apiUrl + "/production/upload_img_production", formData).pipe(map((response: Response) => response))
	}

	insProduccion(
		prodId,
		productionName,
		prodFormatId,
		prodGenreId,
		season,
		chapters,
		pagePerChapter,
		escenePerChapter,
		minPerChapter,
		weeksPreProd,
		weeksProd,
		weeksPost,
		preProductionStartDate,
		productionStartDate,
		productionEndDate,
		postProductionEnddate,
		prodUnits,
		prodPhoto,
		internalOrder
	) {
		let serviceOrderList = {
			id: prodId,
			production_name: productionName,
			prod_format_id: prodFormatId,
			prod_genre_id: prodGenreId,
			season: season,
			chapters: chapters,
			page_per_chapter: pagePerChapter,
			escene_per_chapter: escenePerChapter,
			min_per_chapter: minPerChapter,
			weeks_pre_prod: weeksPreProd,
			weeks_prod: weeksProd,
			weeks_post: weeksPost,
			pre_production_start_date: preProductionStartDate,
			production_start_date: productionStartDate,
			production_end_date: productionEndDate,
			post_production_end_date: postProductionEnddate,
			prod_units: prodUnits,
			prod_image: prodPhoto,
			internal_order: internalOrder
		};
		return this.http.post(environment.apiUrl + "/production/insert_production", serviceOrderList).pipe(map((response: Response) => response));
	}

	getProduccion(prod_id) {
		let fullInfoProd = {
			id: prod_id,
		};

		return new Promise((resolve, reject) => {
			if (this.produccion && this.produccion.id == prod_id) {
				resolve(this.produccion);
			} else {
				this.http
					.post(environment.apiUrl + "/production/get_production", fullInfoProd)
					.pipe(map((response: Response) => response))
					.subscribe((data: any) => {
						this.produccion = data.production;
						this.produccion.prod_units = data.prod_units;
						this.updateStatusProductionEdit(data.production);
						resolve(data);
					});
			}
		});
	}

	getProduccionEdit(prod_id) {
		let fullInfoProd = {
			id: prod_id,
		};

		return this.http.post(environment.apiUrl + "/production/get_production", fullInfoProd).pipe(map((response: Response) => response));
	}

	obtenerCapitulos(prod_id, ordenar = null, offset = null) {
		return this.http.post(environment.apiUrl + "/chapter/get_chapters", { production_id: prod_id, order: ordenar, offset: offset }).pipe(map((response: Response) => response));
	}

	recibirCapitulos(formulario, archivo) {
		return this.http.post(environment.apiUrl + "/chapter/receive_chapter", { values: formulario, xml: archivo }).pipe(map((response: Response) => response));
	}

	confirmarCapitulo(capitulo_id, escenas, reemplazar, file_name, modificar_fecha) {
		let escenasSeleccionadas = {
			chapter_id: capitulo_id,
			scenes: escenas,
			checked: reemplazar,
			file_name: file_name,
			modificar_fecha: modificar_fecha
		};

		// console.log(escenasSeleccionadas)
		return this.http.post(environment.apiUrl + "/chapter/save_scenes_xml", escenasSeleccionadas).pipe(map((response: Response) => response));
	}

	getDatosSelectEscenas(prod_id) {
		let datosEscena = {
			production_id: prod_id,
		};
		return this.http.post(environment.apiUrl + "/production/get_data_ch", datosEscena).pipe(map((response: Response) => response));
	}

	getSetsLocaciones(locacion_id) {
		let datoLocacionId = {
			sc_locations_id: locacion_id,
		};
		return this.http.post(environment.apiUrl + "/production/get_location_set", datoLocacionId).pipe(map((response: Response) => response));
	}

	listEscenas(capitulo_id, prod_id, orden, orderType, isBreakdown = true, getPostData = false) {
		let datosEscena = {
			chapter_id: capitulo_id,
			production_id: prod_id,
			order: orden,
			asc_desc: orderType,
			breakdown: isBreakdown ? isBreakdown : undefined,
			get_comments: getPostData ? getPostData : undefined,
		};
		return this.http.post(environment.apiUrl + "/chapter/get_scenes_chapter_id", datosEscena).pipe(map((response: Response) => response));
	}

	getMetadataScenes(ids_scenes) {
		let data = {
			scenes_id: ids_scenes
		};
		return this.http.post(environment.apiUrl + "/chapter/metadata_scenes", data).pipe(map((response: Response) => response));
	}

	getScenes(production_id, order, filters, offset, chapter_id?, not_scenes?) {
		let data = {
			chapter_id: chapter_id,
			production_id: production_id,
			order: order.field,
			asc_desc: order.type,
			filters: filters,
			breakdown: true,
			get_comments: false,
			offset: offset,
			not_scenes: not_scenes.length ? not_scenes : undefined
		};
		return this.http.post(environment.apiUrl + "/chapter/get_scenes_chapter_id", data).pipe(map((response: Response) => response));
	}

	insEscena(production_id, infoEscena) {
		infoEscena.production_id = production_id;

		return this.http.post(environment.apiUrl + "/scene/insert_scene", infoEscena).pipe(map((response: Response) => response));
	}

	getEscena(escena_id, tentativo_id = null) {
		let datosEscena = {
			id: escena_id,
			tentative_shooting_id: tentativo_id,
		};
		return this.http.post(environment.apiUrl + "/scene/detail_scene", datosEscena).pipe(map((response: Response) => response));
	}

	insComentarioEscena(escena_id, comentario, comentario_id) {
		let datosEscena = {
			scene_id: escena_id,
			id: comentario_id,
			comment: comentario,
		};

		return this.http.post(environment.apiUrl + "/scene/create_comment", datosEscena).pipe(map((response: Response) => response));
	}

	crearCapitulo(numero_capitulo, prod_id) {
		let datosCapitulo = {
			production_id: prod_id,
			number_chapter: numero_capitulo,
		};
		return this.http.post(environment.apiUrl + "/chapter/create_chapter", datosCapitulo).pipe(map((response: Response) => response));
	}

	cancelarCapitulo(capitulo_id) {
		let datosCapitulo = {
			chapter_id: capitulo_id,
		};
		return this.http.post(environment.apiUrl + "/chapter/cancel_chapters", datosCapitulo).pipe(map((response: Response) => response));
	}

	entregarCapitulo(capitulo_id) {
		let datosCapitulo = {
			chapter_id: capitulo_id,
		};
		return this.http.post(environment.apiUrl + "/chapter/deliver_chapters", datosCapitulo).pipe(map((response: Response) => response));
	}

	publicarCapitulo(capitulo_id) {
		let datosCapitulo = {
			chapter_id: capitulo_id,
		};
		return this.http.post(environment.apiUrl + "/chapter/publish_chapters", datosCapitulo).pipe(map((response: Response) => response));
	}

	getPersonajes(prod_id, orden, incluir_desarrollo = true) {
		let datosPersonajes = {
			production_id: prod_id,
			order: orden,
			incluir_desarrollo: incluir_desarrollo,
		};
		return this.http.post(environment.apiUrl + "/character/characters_list", datosPersonajes).pipe(map((response: Response) => response));
	}

	getPersonajesFilter(prod_id, orden, incluir_desarrollo = true, offset, filters) {
		let datosPersonajes = {
			production_id: prod_id,
			order: orden,
			chk_desarrollo: incluir_desarrollo,
			offset: offset,
			rol_id: filters.Rol ? filters.Rol.length ? filters.Rol.join(',') : null : null,
			characters_id: filters.Personaje ? filters.Personaje.length ? filters.Personaje.join(',') : null : null,
			contract_type_id: filters.Contrato ? filters.Contrato.length ? filters.Contrato.join(',') : null : null,
			chapter_from: filters.cap_desde,
			chapter_to: filters.cap_hasta,
		};
		return this.http.post(environment.apiUrl + "/character/characters_list_filter", datosPersonajes).pipe(map((response: Response) => response));
	}

	crearPersonaje(id, production_id, character_name, sc_character_role_id, sc_character_contract_type_id) {
		// console.log(id, production_id, character_name, sc_character_role_id, sc_character_contract_type_id)
		let datosPersonaje = {
			id: id,
			production_id: production_id,
			character_name: character_name,
			sc_character_role_id: sc_character_role_id,
			sc_character_contract_type_id: sc_character_contract_type_id,
		};
		return this.http.post(environment.apiUrl + "/character/insert_character", datosPersonaje).pipe(map((response: Response) => response));
	}

	eliminarPersonaje(character_id) {
		let idPersonaje = {
			id: character_id,
		};
		return this.http.post(environment.apiUrl + "/character/delete_character", idPersonaje).pipe(map((response: Response) => response));
	}

	getAssignActorList(production_id, character_id) {
		let params = {
			production_id: production_id,
			character_id: character_id,
		};
		return this.http.post(environment.apiUrl + "/character/assign_actor", params).pipe(map((response: Response) => response));
	}

	sendUnAssingActor(character_has_actor_id) {
		return this.http.post(environment.apiUrl + "/character/unassign_actor", { character_has_actor_id: character_has_actor_id }).pipe(map((response: Response) => response));
	}

	sendAssignActor(requestParams, production_id, character_id) {
		let params = {
			actor_id: requestParams.actor_id ? requestParams.actor_id : null,
			production_id: production_id,
			first_name: requestParams.first_name,
			last_name: requestParams.last_name,
			artistic_name: requestParams.artistic_name ? requestParams.artistic_name : null,
			init_date: requestParams.init_date,
			contract_end_date: requestParams.end_date,
			character_id: character_id,
			character_has_actor_id: requestParams.character_has_actor_id ? requestParams.character_has_actor_id : null,
			type_document_id: requestParams.type_document_id,
			document: requestParams.document,
			address: requestParams.address,
			email: requestParams.actor_email,
			phone: requestParams.phone,
			telephone: requestParams.telephone,
			status: requestParams.status,
			// "manager_id": requestParams.manager_id,
			// "first_name_manager": requestParams.first_name_manager,
			// "phone_manager": requestParams.phone_manager,
			// "email_manager": requestParams.email_manager,
			// "actor_has_manager_id": requestParams.actor_has_manager_id ? requestParams.actor_has_manager_id : null
		};
		return this.http.post(environment.apiUrl + "/character/send_assign_actor", params).pipe(map((response: Response) => response));
	}
	// "manager_id": requestParams.manager_id ? (requestParams.manager_id == 'null' ? null : requestParams.manager_id) : null,

	getLocaciones(prod_id, orden, incluir_desarrollo = true, offset, filters) {
		let datosLocaciones = {
			production_id: prod_id,
			order: orden,
			incluir_desarrollo: incluir_desarrollo,
			offset: offset,
			locations_id: filters.nombre ? filters.nombre.length ? filters.nombre.join(',') : null : null,
			country_id: filters.paisId,
			city_id: filters.ciudadId,
			chapter_from: filters.cap_desde,
			chapter_to: filters.cap_hasta,
			location_studio_ids: filters.loc_est, 
			day_night_ids: filters.dia_noche,
			inside_outside_ids: filters.int_ext
		};
		return this.http.post(environment.apiUrl + "/location/get_location_production", datosLocaciones).pipe(map((response: Response) => response));
	}

	insLocacion(id, production_id, sc_location_esp, country_id, city_id, name_country, name_city) {
		let datosLocacion = {
			id: id,
			production_id: production_id,
			sc_location_esp: sc_location_esp,
			country_id: country_id,
			city_id: city_id,
			name_country: name_country,
			name_city: name_city,
		};
		return this.http.post(environment.apiUrl + "/location/insert_location", datosLocacion).pipe(map((response: Response) => response));
	}

	eliminarLocacion(location_id) {
		let idLocacion = {
			id: location_id,
		};
		return this.http.post(environment.apiUrl + "/location/delete_location", idLocacion).pipe(map((response: Response) => response));
	}

	eliminarMultiplesLocaciones(locationsIds: string) {
		let params = {
			ids: locationsIds,
		};
		return this.http.post(environment.apiUrl + "/location/delete_multiple_locations", params).pipe(map((response: Response) => response));
	}

	eliminarMultiplesPersonajes(charactersIds: string) {
		let params = {
			ids: charactersIds,
		};
		return this.http.post(environment.apiUrl + "/character/delete_multiple_character", params).pipe(map((response: Response) => response));
	}

	getElementos(prod_id, orden, incluir_desarrollo = true) {
		let datosElementos = {
			production_id: prod_id,
			order: orden,
			incluir_desarrollo: incluir_desarrollo,
		};
		return this.http.post(environment.apiUrl + "/element/elements_list", datosElementos).pipe(map((response: Response) => response));
	}

	crearElemento(id, production_id, element_name, sc_element_category_id) {
		let datosElemento = {
			id: id,
			production_id: production_id,
			element_name: element_name,
			sc_element_category_id: sc_element_category_id,
		};
		return this.http.post(environment.apiUrl + "/element/insert_element", datosElemento).pipe(map((response: Response) => response));
	}

	eliminarElemento(elemento_id) {
		let idElemento = {
			id: elemento_id,
		};
		return this.http.post(environment.apiUrl + "/element/delete_element", idElemento).pipe(map((response: Response) => response));
	}

	eliminarMultiplesElements(elementsIds: string) {
		let params = {
			ids: elementsIds,
		};
		return this.http.post(environment.apiUrl + "/element/delete_multiple_elements", params).pipe(map((response: Response) => response));
	}

	getSets(prod_id, sc_locations_id, orden, incluir_desarrollo = true, filtrarArgumentos) {
		let datosSets = {
			production_id: prod_id,
			sc_locations_id: filtrarArgumentos.locacionId,
			order: orden,
			incluir_desarrollo: incluir_desarrollo,
			inside_outside_ids: filtrarArgumentos.int_ext,
			location_studio_ids: filtrarArgumentos.loc_est,
			day_night_ids: filtrarArgumentos.dia_noche,
			chapter_from: filtrarArgumentos.cap_desde,
			chapter_to: filtrarArgumentos.cap_hasta,
			sc_location_sets_id: filtrarArgumentos.nombre,

		};
		return this.http.post(environment.apiUrl + "/location/get_set_production", datosSets).pipe(map((response: Response) => response));
	}

	insSet(id, sc_locations_id, sc_location_set_esp, sc_location_studio_id) {
		let datosLocacion = {
			id: id,
			sc_locations_id: sc_locations_id,
			sc_location_set_esp: sc_location_set_esp,
			sc_location_studio_id: sc_location_studio_id,
		};
		return this.http.post(environment.apiUrl + "/location/insert_set_location", datosLocacion).pipe(map((response: Response) => response));
	}

	eliminarSet(set_id) {
		let idSet = {
			id: set_id,
		};
		return this.http.post(environment.apiUrl + "/location/delete_set", idSet).pipe(map((response: Response) => response));
	}

	eliminarMultiplesSets(locationsIds: string) {
		let params = {
			ids: locationsIds,
		};
		return this.http.post(environment.apiUrl + "/location/delete_multiple_sets", params).pipe(map((response: Response) => response));
	}

	eliminarEscena(location_id) {
		let idEscena = {
			id: location_id,
		};
		return this.http.post(environment.apiUrl + "/scene/delete_scene", idEscena).pipe(map((response: Response) => response));
	}

	cancelarEscena(escena_id) {
		let idEscena = {
			scene_id: escena_id,
		};
		return this.http.post(environment.apiUrl + "/scene/cancel_scene", idEscena).pipe(map((response: Response) => response));
	}

	desasignarEscena(escena_id) {
		let idEscena = {
			scene_id: escena_id,
		};
		return this.http.post(environment.apiUrl + "/scene/unassign_scene", idEscena).pipe(map((response: Response) => response));
	}

	getEventos(prod_id, orden, incluir_desarrollo = true) {
		let datosEventos = {
			production_id: prod_id,
			order: orden,
			incluir_desarrollo: incluir_desarrollo,
		};
		return this.http.post(environment.apiUrl + "/dashboard/events_production", datosEventos).pipe(map((response: Response) => response));
	}

	crearEventos(name_event, prod_id, type_event_id, id?) {
		let insertarEvento = {
			production_id: prod_id,
			event_esp: name_event,
			sc_event_type_id: type_event_id,
			id: id
		};
		return this.http.post(environment.apiUrl + "/dashboard/create_event", insertarEvento).pipe(map((response: Response) => response));
	}
	eliminarEvento(elemento_id) {
		let idElemento = {
			id: elemento_id,
		};
		return this.http.post(environment.apiUrl + "/dashboard/delete_event", idElemento).pipe(map((response: Response) => response));
	}

	crearTentativo(prod_id, nombre, unidades, id_tentativo = null) {
		let insertarEvento = {
			production_id: prod_id,
			name_tentative: nombre,
			units: unidades,
			id: id_tentativo,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/create_tentative_shooting", insertarEvento).pipe(map((response: Response) => response));
	}

	listarTentativas(prod_id, orden) {
		let insertarEvento = {
			production_id: prod_id,
			order: orden,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/list_tentative_shooting", insertarEvento).pipe(
			map((response: Response) => {
				for (const tent of response["tentatives"]) {
					tent.status_desc = tent.published ? "Publicado" : "";
				}
				return response;
			})
		);
	}

	eliminarTentativo(tentativo_id) {
		let insertarEvento = {
			id: tentativo_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/delete_tentative", insertarEvento).pipe(map((response: Response) => response));
	}

	eliminarEscenaTentativo(id_escena_tentativo) {
		let insertarEvento = {
			id: id_escena_tentativo,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/delete_tentative_scene", insertarEvento).pipe(map((response: Response) => response));
	}

	getDetalleTentativo(tentative_id) {
		let tentativeDato = {
			id: tentative_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/tentative_shooting_detail", tentativeDato).pipe(map((response: Response) => response));
	}

	deleteMultipleTentatives(tentativesIds: string) {
		let params = {
			ids: tentativesIds,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/delete_multiple_tentatives", params).pipe(map((response: Response) => response));
	}

	actualizarOrdenEscenas(escenas) {
		let tentativeDato = {
			scenes: escenas,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/change_order_scenes", tentativeDato).pipe(map((response: Response) => response));
	}

	agregarEscenaTentativo(values, tentativo_id) {
		values = Object.assign(values, { tentative_shooting_id: tentativo_id });

		return this.http.post(environment.apiUrl + "/daily_plan/add_scene_tentative", values).pipe(map((response: Response) => response));
	}

	unificarPersonaje(character, character_to_unify) {
		let unificar = {
			character: character,
			character_to_unify: character_to_unify,
		};
		return this.http.post(environment.apiUrl + "/character/unify_character", unificar).pipe(map((response: Response) => response));
	}

	unificarSet(set, set_to_unify) {
		let unificar = {
			set: set,
			set_to_unify: set_to_unify,
		};
		return this.http.post(environment.apiUrl + "/location/unify_set", unificar).pipe(map((response: Response) => response));
	}

	unificarEvento(event, event_to_unify) {
		let unificar = {
			event: event,
			event_to_unify: event_to_unify,
		};
		return this.http.post(environment.apiUrl + "/event/unify_event", unificar).pipe(map((response: Response) => response));
	}

	getSemanasTentativo(tentative_id) {
		let tentativo = {
			tentative_id: tentative_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/getWeeksToPost", tentativo).pipe(map((response: Response) => response));
	}

	getListaConfirmacionTentativo(tentative_id, week = null) {
		let tentativo = {
			tentative_id: tentative_id,
			week: week,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/list_tentative_confirm", tentativo).pipe(map((response: Response) => response));
	}

	confirmarTentatvo(ts_has_unit_id, date_week) {
		let confirm = {
			ts_has_unit_id: ts_has_unit_id,
			date_week: date_week,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/confirm_daily_plan", confirm).pipe(map((response: Response) => response));
	}

	reporteElemento(elements) {
		// console.log(elements);
		let elemento = {
			elements: elements,
		};
		return this.http.post(environment.apiUrl + "/element/breakdown_elements", elemento).pipe(map((response: Response) => response));
	}

	getParametros(params, namefunction) {
		return this.http.post(environment.apiUrl + `/production/${namefunction}`, params).pipe(map((response: Response) => response));
		//return this.http.post(environment.apiUrl + "/production/getParameters", parametros).pipe(map((response: Response) => response));
	}

	reportePersonajes(production_id, order_des1, order_des0, chapter_number = null, backFilters, offset) {
		let parametros = {
			production_id: production_id,
			order_des: order_des1,
			chapter_number: chapter_number,
			parameter: order_des0,
			incluir_desarrollo: backFilters.incluir_desarrollo,
			sc_location_studio_id: backFilters.loc_est,
			sc_day_night_id: backFilters.dia_noche,
			sc_inside_outside_id: backFilters.int_ext,
			chapter_from: backFilters.cap_desde,
			chapter_to: backFilters.cap_hasta,
			type_contract_id: backFilters.type_contract_id,
			rol_id: backFilters.rol_id,
			search_text: backFilters.search_text,
			offset: offset,
		};
		return this.http.post(environment.apiUrl + "/production/getParametersCharacter", parametros).pipe(map((response: Response) => response));
	}

	reporteSets(production_id, order_des1, order_des0, chapter_number = null, backFilters) {
		let parametros = {
			production_id: production_id,
			order_des: order_des1,
			chapter_number: chapter_number,
			parameter: order_des0,
			incluir_desarrollo: backFilters.incluir_desarrollo,
			sc_location_studio_id: backFilters.loc_est,
			sc_day_night_id: backFilters.dia_noche,
			sc_inside_outside_id: backFilters.int_ext,
			chapter_from: backFilters.cap_desde,
			chapter_to: backFilters.cap_hasta,
		};
		return this.http.post(environment.apiUrl + "/production/getParametersSet", parametros).pipe(map((response: Response) => response));
	}

	reporteLocaciones(production_id, order_des1, order_des0, chapter_number = null, backFilters) {
		let parametros = {
			production_id: production_id,
			order_des: order_des1,
			chapter_number: chapter_number,
			parameter: order_des0,
			incluir_desarrollo: backFilters.incluir_desarrollo,
			sc_location_studio_id: backFilters.loc_est,
			sc_day_night_id: backFilters.dia_noche,
			sc_inside_outside_id: backFilters.int_ext,
			chapter_from: backFilters.cap_desde,
			chapter_to: backFilters.cap_hasta,
		};
		return this.http.post(environment.apiUrl + "/production/getParametersLocation", parametros).pipe(map((response: Response) => response));
	}

	reporteEventos(production_id, order_des1, order_des0, chapter_number = null, backFilters) {
		let parametros = {
			production_id: production_id,
			order_des: order_des1,
			chapter_number: chapter_number,
			parameter: order_des0,
			incluir_desarrollo: backFilters.incluir_desarrollo,
			sc_location_studio_id: backFilters.loc_est,
			sc_day_night_id: backFilters.dia_noche,
			sc_inside_outside_id: backFilters.int_ext,
			chapter_from: backFilters.cap_desde,
			chapter_to: backFilters.cap_hasta,
		};
		return this.http.post(environment.apiUrl + "/production/getParametersEvent", parametros).pipe(map((response: Response) => response));
	}

	getDatosParametros(production_id) {
		let paisesCiudades = {
			production_id: production_id,
		};
		return this.http.post(environment.apiUrl + "/production/getProjectionProduction", paisesCiudades).pipe(map((response: Response) => response));
	}

	insParametro(data) {
		let datosParametro = {
			projection_id: data.projection_id,
			production_id: data.production_id,
			weeks_by_preproduction: data.semanas_preprod,
			weeks_by_production: data.semanas_prod,
			weeks_closing: data.semanas_cierre,
			recording_days: data.dias_grabacion_semanales,
			chapters: data.capitulos,
			min_chapters: data.min_capitulos,
			scenes_by_chapter: data.esc_por_capitulo,
			extra_escenes: data.esc_extra_desglose,
			perc_studio: data.porc_estudio,
			perc_location: data.porc_locacion,
			perc_location_ext_night: data.porc_ext_noche,
			dramatic_days_chapter: data.dias_dram_por_cap,
			total_dramatic_days: data.total_dias_dram,
			protagonists: data.principales,
			capitular_secondary: data.secundarios_capitulares,
			monthly_secondary: data.secundarios_mensuales,
			daily_secondary: data.secundarios_diarios,
			capitular_figurant: data.figurantes_capitulares,
			monthly_figurant: data.figurantes_mensuales,
			daily_figurant: data.figurantes_diarios,
			figurants: data.figurantes,
			extras: data.extras,
			stuntman: data.dobles_accion,
			vehicles: data.vehiculos,
			music: data.musica,
			locations: data.locaciones,
			sets: data.sets,
			minievents: data.mini_eventos,
			events: data.eventos,
			holidays: data.festivos,
			epk_recording: data.grabacion_epk,
			units: data.units,
			special_chapters: data.cap_especiales,
			travels: data.concat_viajes,
		};
		return this.http.post(environment.apiUrl + "/production/projectionEdit", datosParametro).pipe(map((response: Response) => response));
	}

	editPorcentage(projection_id, perc_studio = null, perc_location = null, perc_location_ext_night = null) {
		let porcentaje = {
			projection_id: projection_id,
			perc_studio: perc_studio,
			perc_location: perc_location,
			perc_location_ext_night: perc_location_ext_night,
		};
		return this.http.post(environment.apiUrl + "/production/editPercentageProjection", porcentaje).pipe(map((response: Response) => response));
	}

	editCharacterPorcentage(id, percentage) {
		let porcentaje = {
			id: id,
			percentage: percentage,
		};
		return this.http.post(environment.apiUrl + "/character/update_percentage", porcentaje).pipe(map((response: Response) => response));
	}

	editLocationPorcentage(id, percentage) {
		let porcentaje = {
			id: id,
			percentage: percentage,
		};
		return this.http.post(environment.apiUrl + "/location/update_percentage_location", porcentaje).pipe(map((response: Response) => response));
	}

	editSetPorcentage(id, percentage) {
		let porcentaje = {
			id: id,
			percentage: percentage,
		};
		return this.http.post(environment.apiUrl + "/location/update_percentage_set", porcentaje).pipe(map((response: Response) => response));
	}

	listPlanesDiarios(production_id) {
		let planesDiarios = {
			production_id: production_id,
		};
		return this.http.get(environment.apiUrl + "/daily_plan/getDailyPlans/" + planesDiarios.production_id).pipe(map((response: Response) => response));
	}

	filterPlanesDiarios(production_id) {
		let filtroPlanes = {
			production_id: production_id,
		};
		return this.http.get(environment.apiUrl + "/daily_plan/filterData/" + filtroPlanes.production_id).pipe(map((response: Response) => response));
	}

	detallePlanesDiarios(daily_plan_id) {
		let detalles = {
			daily_plan_id: daily_plan_id,
		};
		return this.http.get(environment.apiUrl + "/daily_plan/detailDailyPlan/" + detalles.daily_plan_id).pipe(map((response: Response) => response));
	}

	listDepartamentos(advanced_call_id = null) {
		let departamentos = {
			advanced_call_id: advanced_call_id,
		};
		return this.http.get(environment.apiUrl + "/daily_plan/add_advanced_call/" + departamentos.advanced_call_id).pipe(map((response: Response) => response));
	}

	insEditDepartamento(daily_plan_id, advanced_call_id, dp_department_id, dp_department, called) {
		let departamentos = {
			daily_plan_id: daily_plan_id,
			advanced_call_id: advanced_call_id,
			dp_department_id: dp_department_id,
			dp_department: dp_department,
			called: called,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/insert_advanced_call", departamentos).pipe(map((response: Response) => response));
	}

	listPaisesCiudades(id = null, production_id) {
		let PaisesCiudades = {
			id: id,
		};
		return this.http.get(environment.apiUrl + "/daily_plan/add_call_place/" + PaisesCiudades.id + "?production_id=" + production_id).pipe(map((response: Response) => response));
	}
	insEditLlamadoAvanzado(id, call_place_id, daily_plan_id, name, address, country_id, city_id, same_address, bc_description, bc_address, name_country, name_city) {
		let formLlamadoAvanzado = {
			id: id,
			call_place_id: call_place_id,
			daily_plan_id: daily_plan_id,
			name: name,
			address: address,
			country_id: country_id,
			city_id: city_id,
			same_address: same_address,
			bc_description: bc_description,
			bc_address: bc_address,
			name_country: name_country,
			name_city: name_city,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/insert_call_place", formLlamadoAvanzado).pipe(map((response: Response) => response));
	}

	listHospitalPaisesCiudades(id = null, production_id) {
		let hospitalPaisesCiudades = {
			id: id,
		};
		return this.http
			.get(environment.apiUrl + "/daily_plan/add_nearest_hospital/" + hospitalPaisesCiudades.id + "?production_id=" + production_id)
			.pipe(map((response: Response) => response));
	}
	insEdithospital(id, nearest_hospital_id, daily_plan_id, name, address, country_id, city_id, name_country, name_city) {
		let formEditHospital = {
			id: id,
			nearest_hospital_id: nearest_hospital_id,
			daily_plan_id: daily_plan_id,
			name: name,
			address: address,
			country_id: country_id,
			city_id: city_id,
			name_country: name_country,
			name_city: name_city,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/insert_nearest_hospital", formEditHospital).pipe(map((response: Response) => response));
	}

	editLlamados(called = null, marking = null, tentative_closure = null, wrap = null, daily_plan_id) {
		let llamados = {
			called: called,
			marking: marking,
			tentative_closure: tentative_closure,
			wrap: wrap,
			daily_plan_id: daily_plan_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/update_dp_calls", llamados).pipe(map((response: Response) => response));
	}

	removeLlamados(advanced_call_id) {
		let llamados = {
			advanced_call_id: advanced_call_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/delete_advanced_call", llamados).pipe(map((response: Response) => response));
	}

	removeLugar(id) {
		let lugar = {
			id: id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/delete_call_place", lugar).pipe(map((response: Response) => response));
	}

	removeHospital(id) {
		let lugar = {
			id: id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/delete_nearest_hospital", lugar).pipe(map((response: Response) => response));
	}

	eliminarPlanDiario(daily_plan_id) {
		let plan = {
			daily_plan_id: daily_plan_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/delete_daily_plan", plan).pipe(map((response: Response) => response));
	}

	cancelarPlanDiario(daily_plan_id) {
		let plan = {
			daily_plan_id: daily_plan_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/cancel_daily_plan", plan).pipe(map((response: Response) => response));
	}

	listCapPlan(production_id) {
		let plan = {
			production_id: production_id,
		};
		return this.http.post(environment.apiUrl + "/chapter/getChaptersProduction", plan).pipe(map((response: Response) => response));
	}

	listEcsPlan(chapter_id) {
		let plan = {
			chapter_id: chapter_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/getScenesChapter", plan).pipe(map((response: Response) => response));
	}

	agregarEscPlan(daily_plan_id, scene_id, order) {
		let plan = {
			daily_plan_id: daily_plan_id,
			scene_id: scene_id,
			order: order,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/add_scene_daily_plan", plan).pipe(map((response: Response) => response));
	}

	desasignarPlan(dp_has_scene_id) {
		let plan = {
			dp_has_scene_id: dp_has_scene_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/unasign_scene", plan).pipe(map((response: Response) => response));
	}

	ordenarEscPlan(daily_plan_id, dp_has_scenes) {
		let plan = {
			daily_plan_id: daily_plan_id,
			dp_has_scenes: dp_has_scenes,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/order_scenes_dp", plan).pipe(map((response: Response) => response));
	}

	agregarAlmuerzo(daily_plan_id, lunch_duration, lunch_order_after, borrar, lunch_seconds) {
		let almuerzo = {
			daily_plan_id: daily_plan_id,
			lunch_duration: lunch_duration,
			lunch_order_after: lunch_order_after,
			delete: borrar,
			lunch_seconds: lunch_seconds,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/dp_edit_launch", almuerzo).pipe(map((response: Response) => response));
	}

	agregarComida(daily_plan_id, meal_duration, meal_order_after, borrar, meal_seconds) {
		let comida = {
			daily_plan_id: daily_plan_id,
			meal_duration: meal_duration,
			meal_order_after: meal_order_after,
			delete: borrar,
			meal_seconds: meal_seconds,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/dp_edit_meal", comida).pipe(map((response: Response) => response));
	}

	agregarDesplazamiento(daily_plan_id, values) {
		let desplazamiento = {
			id: values.id,
			daily_plan_id: daily_plan_id,
			company_move: values.duracion,
			cmove_scene_id: values.id_escena,
			cmove_seconds: values.tiempo,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/dp_edit_company_move", desplazamiento).pipe(map((response: Response) => response));
	}

	deleteCompanyMove(company_move_id) {
		let params = {
			id: company_move_id
		};
		return this.http.post(environment.apiUrl + "/daily_plan/delete_company_move", params).pipe(map((response: Response) => response));
	}

	getLlamados(daily_plan_id) {
		let llamado = {
			daily_plan_id: daily_plan_id,
		};
		return this.http.get(environment.apiUrl + "/daily_plan/get_call_characters/" + llamado.daily_plan_id).pipe(map((response: Response) => response));
	}

	listasLlamado(production_id, contract_type_id, daily_plan_id, call_character_id = null, is_extra: boolean) {
		let llamado = {
			production_id: production_id,
			contract_type_id: contract_type_id,
			daily_plan_id: daily_plan_id,
			call_character_id: call_character_id,
			is_extra: is_extra,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/add_call_character", llamado).pipe(map((response: Response) => response));
	}

	insLlamado(
		dp_call_place_id,
		call_place_id,
		daily_plan_id,
		name,
		address,
		country_id,
		city_id,
		name_country,
		name_city,
		sc_character_id,
		pickup_time,
		on_location_time,
		make_up_time,
		set_time,
		same_address,
		bc_description,
		bc_address,
		call_character_id = null,
		extraParams
	) {
		let llamado = {
			id: dp_call_place_id,
			call_place_id: call_place_id,
			daily_plan_id: daily_plan_id,
			name: name,
			address: address,
			country_id: country_id,
			city_id: city_id,
			name_country: name_country,
			name_city: name_city,

			sc_character_id: sc_character_id,
			pickup_time: pickup_time,
			on_location_time: on_location_time,
			make_up_time: make_up_time,
			set_time: set_time,
			same_address: same_address,
			bc_description: bc_description,
			bc_address: bc_address,
			call_character_id: call_character_id,
		};
		if (extraParams) {
			// Está agregando/editando extra.
			llamado["is_extra"] = extraParams.is_extra;
			llamado["dp_extra_type_id"] = extraParams.dp_extra_type_id;
			llamado["quantity"] = extraParams.quantity;
			llamado["service_id"] = extraParams.service_id;
		}
		return this.http.post(environment.apiUrl + "/daily_plan/insert_call_character", llamado).pipe(map((response: Response) => response));
	}
	/**
	 * Edita o agrega un campo de las tablas dentro de la pestaña llamados,
	 * de un plan diario.
	 * @param call_character_id id del personaje
	 * @param fieldType tipo de campo a editar/agregar (ex: wrap_time)
	 * @param hour hora asignada
	 */
	actualizarCamposLlamadosTalento(params: any, fieldType, hour): Observable<any> {
		switch (fieldType) {
			case "arrived_time":
				params["arrived_time"] = hour;
				break;
			case "wrap_time":
				params["wrap_time"] = hour;
				break;
			case "make_up_time":
				params["make_up_time"] = hour;
				break;
			case "pickup_time":
				params["pickup_time"] = hour;
				break;
			case "on_location_time":
				params["on_location_time"] = hour;
				break;
			case "set_time":
				params["set_time"] = hour;
				break;
		}

		return this.http.post(environment.apiUrl + "/daily_plan/update_arrived_time", params).pipe(map((response: Response) => response));
	}

	removeLlamado(call_character_id) {
		let llamado = {
			call_character_id: call_character_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/delete_call_character", llamado).pipe(map((response: Response) => response));
	}

	restartLlamado(call_character_id) {
		let llamado = {
			call_character_id: call_character_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/restart_call", llamado).pipe(map((response: Response) => response));
	}

	reporteEvento(production_id, events) {
		let evento = {
			production_id: production_id,
			events: events,
		};
		return this.http.post(environment.apiUrl + "/event/breakdown_events", evento).pipe(map((response: Response) => response));
	}

	getServicios(daily_plan_id) {
		let params = {
			daily_plan_id: daily_plan_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/get_services", params).pipe(map((response: Response) => response));
	}
	deleteServicio(service_id) {
		let params = {
			service_id: service_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/delete_service", params).pipe(map((response: Response) => response));
	}
	noUtilizarServicio(service_id) {
		let params = {
			service_id: service_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/unused_service", params).pipe(map((response: Response) => response));
	}
	/**
	 *
	 * @param daily_plan_id id del plan diario
	 * @param service_id id del servicio en caso de editar.
	 */
	listasServicios(daily_plan_id?, service_id = null) {
		let params = {
			daily_plan_id: daily_plan_id,
			service_id: service_id,
			is_extra: true,
		};

		return this.http.post(environment.apiUrl + "/daily_plan/add_service", params).pipe(map((response: Response) => response));
	}

	insSoporteServicios(file, daily_plan_id) {
		// Ajusta parámetros para petición

		let formData = new FormData();

		formData.append('service_file', file);
		formData.append("id", daily_plan_id)


		return this.http.post(environment.apiUrl + "/daily_plan/update_service_file", formData).pipe(map((response: Response) => response));
	}

	insServicios(params, daily_plan_id?) {
		// Ajusta parámetros para petición

		let formData = new FormData();

		Object.keys(params).map(key => {
			if (key != 'fileSelected' && key != 'scenes')
				formData.append(key, params[key]);
		})

		if (daily_plan_id) formData.append("daily_plan_id", daily_plan_id);

		for (let idScene of params.scenes) {
			formData.append("ids_scenes[]", idScene)
		}

		// let headers = new HttpHeaders({"Content-Type": 'multipart/form-data' }) { headers: headers }

		return this.http.post(environment.apiUrl + "/daily_plan/insert_service", formData).pipe(map((response: Response) => response));
	}

	getCrews(production_id, orden = null) {
		let params = {
			production_id: production_id,
			order: orden,
		};
		return this.http.post(environment.apiUrl + "/crew/get_crews", params).pipe(map((response: Response) => response));
	}

	getListasCrews(production_id, prod_unit_has_crew_id = null, user_id) {
		let params = {
			production_id: production_id,
			prod_unit_has_crew_id: prod_unit_has_crew_id,
			user_id: user_id,
		};
		return this.http.post(environment.apiUrl + "/crew/add_crew", params).pipe(map((response: Response) => response));
	}

	insCrew(params, production_id) {
		params.production_id = production_id;
		return this.http.post(environment.apiUrl + "/crew/insert_crew", params).pipe(map((response: Response) => response));
	}

	eliminarCrew(user_id) {
		let params = {
			user_id: user_id,
		};
		return this.http.post(environment.apiUrl + "/crew/delete_crew", params).pipe(map((response: Response) => response));
	}

	crewValidateEmail(email, production_id) {
		let params = {
			email: email,
			production_id: production_id,
		};
		return this.http.post(environment.apiUrl + "/crew/validate_email", params).pipe(map((response: Response) => response));
	}

	getCrewDailyPlan(daily_plan_id) {
		let params = {
			daily_plan_id: daily_plan_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/getCrewDp", params).pipe(map((response: Response) => response));
	}

	getListasCrewDailyPlan(daily_plan_id) {
		let params = {
			daily_plan_id: daily_plan_id,
		};
		return this.http.post(environment.apiUrl + "/crew/add_crew ", params).pipe(map((response: Response) => response));
	}

	updateTimeCrewDailyPlan(selectedItem, daily_plan_id, fieldType, hour) {
		let params = {
			// "prod_unit_has_crew": selectedItem.id
			dp_has_crew_id: selectedItem.id ? selectedItem.id : undefined,
			prod_unit_has_crew_id: selectedItem.id ? undefined : selectedItem.prod_unit_has_crew_id,
			daily_plan_id: daily_plan_id,
		};
		switch (fieldType) {
			case "arrived_time":
				params["arrived_time"] = hour;
				break;
			case "wrap_time":
				params["wrap_time"] = hour;
				break;
			case "pickup_time":
				params["pickup_time"] = hour;
				break;
			case "on_location_time":
				params["on_location_time"] = hour;
				break;
		}

		return this.http.post(environment.apiUrl + "/daily_plan/update_time_crew", params).pipe(map((response: Response) => response));
	}

	crewOnCallDailyPlan(prod_unit_has_crew) {
		let params = {
			prod_unit_has_crew: prod_unit_has_crew,
		};

		return this.http.post(environment.apiUrl + "/daily_plan/crew_on_call", params).pipe(map((response: Response) => response));
	}

	insCrewDailyPlan(params, daily_plan_id) {
		params["daily_plan_id"] = daily_plan_id;
		return this.http.post(environment.apiUrl + "/daily_plan/insert_crew_dp", params).pipe(map((response: Response) => response));
	}

	removeCrewDailyPlan(prod_unit_has_crew) {
		let params = {
			prod_unit_has_crew: prod_unit_has_crew,
		};

		return this.http.post(environment.apiUrl + "/daily_plan/delete_crew_dp", params).pipe(map((response: Response) => response));
	}

	/**
	 * Retorna BehaviorSubject para que un componente de plan diario
	 * se pueda suscribir y actualizar cuando el valor cambie.
	 */
	get shouldReloadRequestsDailyPlan(): BehaviorSubject<boolean> {
		return this._shouldReloadRequestsDailyPlan;
	}
	/**
	 * Emite un nuevo valor al BehaviorSubject haciendo que los componentes donde estan suscritos
	 * se actualicen
	 */
	updateDailyPlanRequests() {
		this._shouldReloadRequestsDailyPlan.next(true);
	}

	getDailyPlanUnitId() {
		return this._dailyPlanUnitId;
	}

	setDailyPlanUnitId(unitId: number) {
		this._dailyPlanUnitId = unitId;
	}

	getCommentsDailyPlan(daily_plan_id, dp_has_scene_id?) {
		let params = {
			daily_plan_id: daily_plan_id,
			dp_has_scene_id: dp_has_scene_id ? dp_has_scene_id : null
		};
		return this.http.post(environment.apiUrl + "/daily_plan/getComments", params).pipe(map((response: Response) => response));
	}

	insertCommentDailyPlan(comment, daily_plan_id, comment_id = null) {
		let params = {
			daily_plan_id: daily_plan_id,
			comment: comment,
			id: comment_id,
		};

		if (comment_id === null) {
			delete params.id;
		}

		return this.http.post(environment.apiUrl + "/daily_plan/insert_comment", params).pipe(map((response: Response) => response));
	}

	deleteCommentDailyPlan(comment_id) {
		let params = {
			id: comment_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/delete_comment", params).pipe(map((response: Response) => response));
	}

	changeDailyPlanStatus(daily_plan_id) {
		let params = {
			daily_plan_id: daily_plan_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/change_status_daily_plan", params).pipe(map((response: Response) => response));
	}

	setDailyPlanStatus(daily_plan_id, new_status_dp_id) {
		let params = {
			daily_plan_id: daily_plan_id,
			daily_plan_status_id: new_status_dp_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/change_status_dp", params).pipe(map((response: Response) => response));
	}

	rejectServiceDailyPlan(service_id, comment, weekInfo) {
		let params = {
			service_id: service_id,
			comment: comment,
			date_from: weekInfo.from,
			date_to: weekInfo.to,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/no_approve_service", params).pipe(map((response: Response) => response));
	}
	approveServiceDailyPlan(service_id) {
		let params = {
			service_id: service_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/approve_service", params).pipe(map((response: Response) => response));
	}

	get dailyPlanStatusId() {
		return this._dailyPlanStatusId;
	}

	set dailyPlanStatusId(dpStatusId) {
		this._dailyPlanStatusId = dpStatusId;
	}

	get dailyPlanWeekStatus() {
		return this._dailyPlanWeekStatus;
	}

	set dailyPlanWeekStatus(weekStatus) {
		this._dailyPlanWeekStatus = weekStatus;
	}

	canEditServicesDailyPlan(): boolean {
		if (this._dailyPlanWeekStatus.id == 1 || this._dailyPlanWeekStatus.id == 4) return true;
		return false;
	}

	sectionIsPublished(section_id: DailyPlanSections) {
		let foundSection = this.dailyPlanPublishedSections.find((s) => s.section_id == section_id);
		return foundSection ? true : false;
	}

	enableFeatureDailyPlan(feature: string) {
		//daily_plan_status_id
		switch (this._dailyPlanStatusId) {
			case DailyPlanStatus.Canceled: {
				if (feature == "order scene") return false;
				if (feature == "add") return false;
				if (feature == "add service") return false;
				if (feature == "approve service") return false;
				if (feature == "edit call crew") return false;
				if (feature == "edit call talent") return false;
				if (feature == "delete call") return false;
				if (feature == "general") return false;
				if (feature == "comments") return false;
				if (feature == "wrap edit") return false;
				if (feature == "wrap show") return true;
				if (feature == "produce scene") return false;
				if (feature == "estimate call") return false;
				break;
			}
			case DailyPlanStatus.Private: {
				if (feature == "order scene") return true;
				if (feature == "add") return true;
				if (feature == "add service") return this.canEditServicesDailyPlan();
				if (feature == "approve service") return this.canEditServicesDailyPlan() && this.sectionIsPublished(DailyPlanSections.Services);
				if (feature == "edit call crew") return true;
				if (feature == "edit call talent") return true;
				if (feature == "delete call") return true;
				if (feature == "general") return true;
				if (feature == "wrap edit") return false;
				if (feature == "wrap show") return false;
				if (feature == "comments") return true;
				if (feature == "produce scene") return false;
				if (feature == "estimate call") return true;
				break;
			}
			case DailyPlanStatus.Open: {
				if (feature == "order scene") return true;
				if (feature == "add") return true;
				if (feature == "add service") return this.canEditServicesDailyPlan();
				if (feature == "approve service") return this.canEditServicesDailyPlan() && this.sectionIsPublished(DailyPlanSections.Services);
				if (feature == "edit call crew") return true;
				if (feature == "edit call talent") return true;
				if (feature == "delete call") return true;
				if (feature == "general") return true;
				if (feature == "wrap edit") return false;
				if (feature == "wrap show") return false;
				if (feature == "comments") return true;
				if (feature == "produce scene") return false;
				if (feature == "estimate call") return false;
				break;
			}
			case DailyPlanStatus.Called: {
				if (feature == "order scene") return false;
				if (feature == "add") return false;
				if (feature == "add service") return this.canEditServicesDailyPlan() && !this.sectionIsPublished(DailyPlanSections.Services);
				if (feature == "approve service") return this.canEditServicesDailyPlan() && this.sectionIsPublished(DailyPlanSections.Services);
				if (feature == "edit call crew") return !this.sectionIsPublished(DailyPlanSections.Crew);
				if (feature == "edit call talent") return !this.sectionIsPublished(DailyPlanSections.Talents);
				if (feature == "delete call") return false;
				if (feature == "general") return true;
				if (feature == "wrap edit") return true;
				if (feature == "wrap show") return true;
				if (feature == "comments") return true;
				if (feature == "produce scene") return true;
				if (feature == "estimate call") return false;
				break;
			}
			case DailyPlanStatus.Recording: {
				if (feature == "order scene") return false;
				if (feature == "add") return false;
				if (feature == "add service") return this.canEditServicesDailyPlan() && !this.sectionIsPublished(DailyPlanSections.Services);
				if (feature == "approve service") return this.canEditServicesDailyPlan() && this.sectionIsPublished(DailyPlanSections.Services);
				if (feature == "edit call crew") return !this.sectionIsPublished(DailyPlanSections.Crew);
				if (feature == "edit call talent") return !this.sectionIsPublished(DailyPlanSections.Talents);
				if (feature == "delete call") return false;
				if (feature == "general") return true;
				if (feature == "wrap edit") return true;
				if (feature == "wrap show") return true;
				if (feature == "comments") return true;
				if (feature == "produce scene") return true;
				if (feature == "estimate call") return false;
				break;
			}
			case DailyPlanStatus.Closed: {
				if (feature == "order scene") return false;
				if (feature == "add") return false;
				if (feature == "add service") return this.canEditServicesDailyPlan() && !this.sectionIsPublished(DailyPlanSections.Services);
				if (feature == "approve service") return this.canEditServicesDailyPlan() && this.sectionIsPublished(DailyPlanSections.Services);
				if (feature == "edit call crew") return !this.sectionIsPublished(DailyPlanSections.Crew);
				if (feature == "edit call talent") return !this.sectionIsPublished(DailyPlanSections.Talents);
				if (feature == "delete call") return false;
				if (feature == "general") return false;
				if (feature == "wrap edit") return false;
				if (feature == "wrap show") return true;
				if (feature == "comments") return true;
				if (feature == "produce scene") return false;
				if (feature == "estimate call") return false;
				break;
			}
		}
	}

	getWeeks(production_id) {
		let params = {
			production_id: production_id,
		};
		return this.http.post(environment.apiUrl + "/week/getWeeks", params).pipe(
			map((response: any) => {
				for (const week of response.array_weeks) {
					if (week.number.startsWith("P")) {
						week.isPreProduction = true;
					}
				}
				return response;
			})
		);
	}

	getDetailWeek(production_id, date_from, date_to) {
		let params = {
			production_id: production_id,
			from: date_from,
			to: date_to,
		};
		return this.http.post(environment.apiUrl + "/week/detail_week", params).pipe(
			map((response: any) => {
				for (const adv of response.advances) {
					let concat_username = adv.firstname ? adv.firstname : "";
					concat_username += adv.lastname ? ` ${adv.lastname}` : "";
					adv.concat_username = concat_username;
				}
				return response;
			})
		);
	}

	approveOrRejectWeekExpenses(production_id, weekInfo, comment, week_expense_status_id, reverse_week?) {
		let params = {
			production_id: production_id,
			date_from: weekInfo.from,
			date_to: weekInfo.to,
			comment: comment,
			week_expense_status_id: week_expense_status_id,
			reverse_week: reverse_week
		};
		return this.http.post(environment.apiUrl + "/week/approve_week", params).pipe(map((response: Response) => response));
	}


	addObservationWeekExpenses(production_id, weekInfo, observation) {
		let params = {
			production_id: production_id,
			date_from: weekInfo.from,
			date_to: weekInfo.to,
			observation: observation,
			week_observation_id: weekInfo.week_observation_id ? weekInfo.week_observation_id : null,
		};
		return this.http.post(environment.apiUrl + "/week/observation_week", params).pipe(map((response: Response) => response));
	}

	editQuantityServiceWeekExpenses(service_id, quantity) {
		let params = {
			service_id: service_id,
			quantity: quantity,
		};
		return this.http.post(environment.apiUrl + "/week/edit_quantity_service", params).pipe(map((response: Response) => response));
	}

	detailSceneDaily(dp_has_scene_id) {
		let data = {
			dp_has_scene_id: dp_has_scene_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/detail_scene_daily", data).pipe(map((response: Response) => response));
	}

	updateTimeScene(dp_has_scene_id, time_arrival, time_start, time_end, time_produced, scene_id = null, scene_status_id = null, observation = null) {
		let data = {
			dp_has_scene_id: dp_has_scene_id,
			time_arrival: time_arrival,
			time_start: time_start,
			time_end: time_end,
			time_produced: time_produced,
			scene_id: scene_id,
			scene_status_id: scene_status_id,
			observation: observation,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/update_time_scene", data).pipe(map((response: Response) => response));
	}

	updateTimeEstimateScene(dp_has_scene_id, time_arrival) {
		let data = {
			dp_has_scene_id: dp_has_scene_id,
			scene_time_estimated: time_arrival,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/update_time_estimated_scene", data).pipe(map((response: Response) => response));
	}

	updateObservationScene(selectedScene, observation, isDetailScene = false) {
		let data = {
			dp_has_scene_id: isDetailScene ? selectedScene.dp_has_scene_id : selectedScene.id,
			scene_id: isDetailScene ? selectedScene.id : selectedScene.scene_id,
			time_arrival: selectedScene.time_arrival,
			time_start: selectedScene.time_start,
			time_end: selectedScene.time_end,
			time_produced: selectedScene.time_produced,
			observation: observation,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/update_time_scene", data).pipe(map((response: Response) => response));
	}

	getClips(dp_has_scene_id, scene_clip_id = null) {
		let data = {
			dp_has_scene_id: dp_has_scene_id,
			scene_clip_id: scene_clip_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/list_clips", data).pipe(map((response: Response) => response));
	}

	insClips(requestParams) {
		let data = {
			scene_clip_id: requestParams.scene_clip_id ? requestParams.scene_clip_id : null,
			dp_has_scene_id: requestParams.dp_has_scene_id,
			shot: requestParams.shot,
			fragment: requestParams.fragment,
			start_tc: requestParams.start_tc,
			end_tc: requestParams.end_tc,
			observation: requestParams.observation,
			clips: requestParams.camera_clip,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/insert_clip", data).pipe(map((response: Response) => response));
	}

	deleteClip(scene_clip_id) {
		let data = {
			scene_clip_id: scene_clip_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/delete_clip", data).pipe(map((response: Response) => response));
	}

	getReportPlanDiario(daily_plan_id) {
		let data = {
			daily_plan_id: daily_plan_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/report_pdf_dp", data).pipe(map((response: Response) => response));
	}

	unproduceScene(scene_id) {
		let data = {
			scene_id: scene_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/unproduce_scene", data).pipe(map((response: Response) => response));
	}

	moveDailyPlan(formData) {
		let data = {
			date: formData.date,
			prod_unit_id: formData.prod_unit_id,
			daily_plan_id: formData.daily_plan_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/move_daily_plan", data).pipe(map((response: Response) => response));
	}

	editFieldWeek(production_id, date_from, date_to, value, type) {
		let data = {
			production_id: production_id,
			date_from: date_from,
			date_to: date_to,
			chapter_projected: type == "chapters" ? value : undefined,
			scene_projected: type == "scenes" ? value : undefined,
			min_projected: type == "minutes" ? value : undefined,
		};
		return this.http.post(environment.apiUrl + "/week/week_projected", data).pipe(map((response: Response) => response));
	}

	insertServiceWeek(params, production_id, date_from, date_to) {
		let formData = new FormData();

		Object.keys(params).map(key => {
			if (key != 'fileSelected')
				formData.append(key, params[key]);
		})

		formData.append("production_id", production_id)
		formData.append("date_from", date_from)
		formData.append("date_to", date_to)



		return this.http.post(environment.apiUrl + "/week/insert_service_week", formData).pipe(map((response: Response) => response));
	}

	getAdvances(production_id) {
		let params = {
			production_id: production_id,
			user_id: this._gS.getCurrentUser().id,
		};
		return this.http.post(environment.apiUrl + "/production/get_advances", params).pipe(
			map((response: any) => {
				for (const adv of response.advances) {
					let concat_username = adv.firstname ? adv.firstname : "";
					concat_username += adv.lastname ? ` ${adv.lastname}` : "";
					adv.concat_username = concat_username;
				}
				return response;
			})
		);
	}

	addAdvanceProduction(advance_id, production_id) {
		let params = {
			advance_id: advance_id,
			production_id: production_id,
			user_id: this._gS.getCurrentUser().id,
		};
		return this.http.post(environment.apiUrl + "/production/add_advance", params).pipe(map((response: Response) => response));
	}

	insertAdvanceProduction(data, advance_id, production_id) {

		const formData = new FormData()
		const params = {
			dp_department_id: data.dp_department_id,
			card_id: data.card_id,
			date: data.date,
			production_id: production_id,
			advance_id: advance_id,
			user_id: data.user_id,
			advances_currency_id: data.advances_currency_id,
			num_account: data.num_account,
			advance_type_id: data.advance_type_id,
			accounts_type_id: data.accounts_type_id,
		};

		for (var key in params) {
			if (params[key] != null)
				formData.append(key, params[key]);
		}

		data.advance_detail.forEach(element => {
			for (var key in element) {
				if (element[key])
					formData.append(`advance_detail[][${key}]`, element[key]);
			}

		});
		formData.append('file', data.file)
		return this.http.post(environment.apiUrl + "/production/insert_advance", formData).pipe(map((response: Response) => response));
	}

	deleteAdvance(advance_id) {
		let params = {
			advance_id: advance_id,
		};
		return this.http.post(environment.apiUrl + "/production/delete_advance", params).pipe(map((response: Response) => response));
	}

	deleteAdvanceDetail(advance_detail_id) {
		let params = {
			advance_detail_id: advance_detail_id,
		};
		return this.http.post(environment.apiUrl + "/production/delete_advance_detail", params).pipe(map((response: Response) => response));
	}

	getUsersDepartment(production_id, department_id) {
		let params = {
			production_id: production_id,
			department_id: department_id,
		};
		return this.http.post(environment.apiUrl + "/production/getUsersDepartment", params).pipe(map((response: Response) => response));
	}

	getCardsUser(user_id) {
		let params = {
			user_id: user_id,
		};
		return this.http.post(environment.apiUrl + "/production/getCardsUser", params).pipe(map((response: Response) => response));
	}

	changeStatusAdvance(advance_id, week_expense_status_id) {
		let params = {
			advance_id: advance_id,
			week_expense_status_id: week_expense_status_id,
		};
		return this.http.post(environment.apiUrl + "/production/approve_advance", params).pipe(map((response: Response) => response));
	}

	publishSection(daily_plan_id, section_id) {
		let params = {
			daily_plan_id: daily_plan_id,
			section_id: section_id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/close_section", params).pipe(map((response: Response) => response));
	}

	unpublishSection(id) {
		let params = {
			id: id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/open_section", params).pipe(map((response: Response) => response));
	}

	validateDocumentUser(type_document_id, document) {
		let params = {
			type_document_id: type_document_id,
			document: document,
		};
		return this.http.post(environment.apiUrl + "/crew/validate_user_document", params).pipe(map((response: Response) => response));
	}

	disableCrew(prod_unit_has_crew_id, disabled) {
		let params = {
			prod_unit_has_crew_id: prod_unit_has_crew_id,
			disabled: disabled,
		};
		return this.http.post(environment.apiUrl + "/crew/disable_crew", params).pipe(map((response: Response) => response));
	}

	getHistoryPayments(production_id) {
		let params = {
			production_id: production_id,
		};
		return this.http.post(environment.apiUrl + "/character/date_reports", params).pipe(map((response: Response) => response));
	}

	getContinuities(production_id) {
		let params = {
			production_id: production_id,
		};
		return this.http.post(environment.apiUrl + "/continuity/getContinuities", params).pipe(map((response: Response) => response));
	}

	getContinuitiesByCharacter(production_id, sc_character_id) {
		let params = {
			sc_character_id: sc_character_id,
			production_id: production_id
		};
		return this.http.post(environment.apiUrl + "/continuity/charachterContinuityDays", params).pipe(map((response: Response) => response));
	}

	getContinuitiesByDay(production_id, day_history) {
		let params = {
			day_history: day_history,
			production_id: production_id
		};
		return this.http.post(environment.apiUrl + "/continuity/characters_continuity_day", params).pipe(map((response: Response) => response));
	}

	getContiuitiesAddLists(production_id) {
		let params = {
			production_id: production_id,
		};
		return this.http.post(environment.apiUrl + "/continuity/add_continuity", params).pipe(map((response: Response) => response));
	}

	addContinuities(continuities, production_id) {
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'multipart/form-data');
		headers.append('Accept', 'application/json');

		const options = { headers: headers };

		const params = {
			continuities: continuities,
			production_id: production_id
		}

		return this.http.post(environment.apiUrl + "/continuity/insert_continuities", continuities, options).pipe(map((response: Response) => response));
	}

	getContiuitiesListsData(production_id) {
		let params = {
			production_id: production_id,
		};
		return this.http.post(environment.apiUrl + "/continuity/dataContinuityProduction", params).pipe(map((response: Response) => response));
	}

	getContinuitiesImages(production_id, sc_character_id, day_history) {
		let params = {
			sc_character_id: sc_character_id,
			day_history: day_history,
			production_id: production_id
		};
		return this.http.post(environment.apiUrl + "/continuity/getImagesContinuity", params).pipe(map((response: Response) => response));
	}

	deleteContinuityImage(image_id) {
		let params = {
			id: image_id,
		};
		return this.http.post(environment.apiUrl + "/continuity/delete_img_continuity", params).pipe(map((response: Response) => response));
	}

	saveCommentSide(values) {
		let params = {
			id: values.id,
			comment: values.comment,
			char_from: values.char_from,
			char_to: values.char_to,
			dp_has_scene_id: values.dp_has_scene_id
		};
		return this.http.post(environment.apiUrl + "/daily_plan/insert_comment_side", params).pipe(map((response: Response) => response));
	}

	deleteCommentSide(values) {
		let params = {
			id: values.id
		};
		return this.http.post(environment.apiUrl + "/daily_plan/delete_comment_side", params).pipe(map((response: Response) => response));
	}

	getDashboardEscenasCapitulos(production_id) {
		let params = {
			production_id: production_id
		};
		return this.http.post(environment.apiUrl + "/dashboard/estado_escenas", params).pipe(map((response: Response) => response));
	}

	getDashboardCapitulosXMes(production_id) {
		let params = {
			production_id: production_id
		};
		return this.http.post(environment.apiUrl + "/dashboard/capitulos_x_mes", params).pipe(map((response: Response) => response));


	}

	getFuturosRendimientos(production_id) {
		let params = {
			production_id: production_id
		};
		return this.http.post(environment.apiUrl + "/dashboard/futuro_rendimiento", params).pipe(map((response: Response) => response));


	}

	getProjectCalendar(production_id, date_month) {
		let params = {
			production_id: production_id,
			date_month: date_month
		};
		return this.http.post(environment.apiUrl + "/production/get_calendar", params).pipe(map((response: Response) => response));
	}

	addCharacterEventCalendar(values) {
		let params = {
			id: values.id ? values.id : undefined,
			sc_character_id: values.sc_character_id,
			date_event_from: values.date_event_from,
			date_event_to: values.date_event_to
		};
		return this.http.post(environment.apiUrl + "/production/add_calendar_event", params).pipe(map((response: Response) => response));
	}

	addLocationEventCalendar(values) {
		let params = {
			id: values.id ? values.id : undefined,
			sc_location_id: values.sc_location_id,
			date_from: values.date_from,
			date_to: values.date_to
		};
		return this.http.post(environment.apiUrl + "/production/addCalendarLocation", params).pipe(map((response: Response) => response));
	}

	addHolidayCalendar(values) {
		let params = {
			id: values.id ? values.id : undefined,
			production_id: values.production_id,
			datdescriptione: values.comment ? values.comment : undefined,
			date: values.date
		};
		return this.http.post(environment.apiUrl + "/production/addHoliday", params).pipe(map((response: Response) => response));
	}

	addCommentCalendar(values) {
		let params = {
			id: values.id ? values.id : undefined,
			production_id: values.production_id,
			date: values.date,
			comment: values.comment,
			user_id: JSON.parse(sessionStorage.getItem("clap_access")).user.id
		};
		return this.http.post(environment.apiUrl + "/production/addCalendarComment", params).pipe(map((response: Response) => response));
	}

	deleteCharacterEventCalendar(id) {
		let params = {
			id: id
		};
		return this.http.post(environment.apiUrl + "/production/delete_calendar_event", params).pipe(map((response: Response) => response));
	}

	deleteHolidayCalendar(id) {
		let params = {
			id: id
		};
		return this.http.post(environment.apiUrl + "/production/delete_holiday", params).pipe(map((response: Response) => response));
	}

	deleteLocationCalendar(id) {
		let params = {
			id: id
		};
		return this.http.post(environment.apiUrl + "/production/delete_location", params).pipe(map((response: Response) => response));
	}

	deleteCommentCalendar(id) {
		let params = {
			id: id
		};
		return this.http.post(environment.apiUrl + "/production/delete_comment", params).pipe(map((response: Response) => response));
	}

	saveShooting(unitId: number, dateWeek: string) {
		let paramsRequest = {
			prod_unit_id: unitId,
			date_week: dateWeek
		};
		return this.http.post(environment.apiUrl + "/daily_plan/create_daily_plan", paramsRequest).pipe(map((response: Response) => response));
	}

	reversarEscena(escena_id) {
		let idEscena = {
			id: escena_id,
		};
		return this.http.post(environment.apiUrl + "/scene/reverse_scene", idEscena).pipe(map((response: Response) => response));
	}

	getServicesProd(prodId: number) {
		return this.http.get(environment.apiUrl + "/daily_plan/list_all_service_per_prod/" + prodId).pipe(map((response: Response) => response));
	}

	getServicesDetail(prodId: number, servId: number) {
		let queryParams = {
			production_id: prodId,
			service_id: servId
		}
		return this.http.post(environment.apiUrl + "/daily_plan/get_service_detail", queryParams).pipe(map((response: Response) => response));
	}

	getDailyPlanServiceDetail(prodId, serviceId) {
		let queryParams = {
			production_id: prodId,
			service_id: serviceId
		}
		return this.http.post(environment.apiUrl + "/daily_plan/get_service_detail_in_daily_plan", queryParams).pipe(map((response: Response) => response));
	}

	getDailyPlanServicesDetail(prodId, planId) {
		let queryParams = {
			production_id: prodId,
			daily_plan_id: planId
		}
		return this.http.post(environment.apiUrl + "/daily_plan/list_all_service_per_dp", queryParams).pipe(map((response: Response) => response));
	}

	addServicesObservations(params: any) {
		let queryParams = {
			dp_service_observation_id: params.id,
			service_id: params.serviceId,
			observation: params.comment,
			status: params.status
		};
		return this.http.post(environment.apiUrl + "/daily_plan/add_service_observations", queryParams).pipe(map((response: Response) => response));
	}

	getServicesfilter(params: any,) {
		let queryParams = {
			production_id: params.proId,
			prod_units: params.unidad,
			service_categories: params.category,
			service_providers: params.provider,
			service_status: params.statu,
			manual: params.manual,
			search_bar: params.search_bar || undefined,
			date_from: params.date_from || undefined,
			date_to: params.date_to || undefined,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/list_all_service_per_prod", queryParams).pipe(map((response: Response) => response));
	}

	aprobarServicio(values: any) {

		return this.http.post(environment.apiUrl + "/daily_plan/legalize_services", values).pipe(map((response: Response) => response));
	}
	rechazarServicio(values: any) {

		return this.http.post(environment.apiUrl + "/daily_plan/legalize_services", values).pipe(map((response: Response) => response));
	}
	editarServicio(values: any) {
		return this.http.post(environment.apiUrl + "/daily_plan/legalize_services", values)
	}
	legalizarServicio(values: any) {
		return this.http.post(environment.apiUrl + "/daily_plan/legalize_services", values)
	}
	getLiquidaciones(values: any) {
		return this.http.post(environment.apiUrl + "/daily_plan/list_services_legalizased", values).pipe(map((response: Response) => response));
	}
	addOrderNumber(values: any) {
		return this.http.post(environment.apiUrl + "/daily_plan/assign_order_number", values).pipe(map((response: Response) => response));
	}
	addServiceNumber(values: any) {
		return this.http.post(environment.apiUrl + "/daily_plan/set_service_number", values).pipe(map((response: Response) => response));
	}
	getServicesLiqui(params: any,) {
		let queryParams = {
			production_id: params.proId,
			providers_id: params.provider,
			search_bar: params.search_bar || undefined,
			date_from: params.date_from || undefined,
			date_to: params.date_to || undefined,
			week_from: params.week_from || undefined,
			week_to: params.week_to || undefined,
			order_number: params.order_number || undefined,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/list_services_legalizased", queryParams).pipe(map((response: Response) => response));
	}
/* 	editCheckSer(params: any,) {
		let queryParams = {
			production_id: params.proId,
			provider_id: params.provider_name,
			search_bar: params.search_bar || undefined,
			
		};
		return this.http.post(environment.apiUrl + "daily_plan/edit_service_with_check", queryParams).pipe(map((response: Response) => response));
	} */
	insCheckServicios(params) {
		// Ajusta parámetros para petición

		let formData = new FormData();

		Object.keys(params).map(key => {
			if (key != 'fileSelected' && key != 'scenes')
				formData.append(key, params[key]);
		})

		// let headers = new HttpHeaders({"Content-Type": 'multipart/form-data' }) { headers: headers }

		return this.http.post(environment.apiUrl + "/daily_plan/edit_service_with_check", formData).pipe(map((response: Response) => response));
	}

	insertCommentService(params) {
		let queryParams = {
			daily_plan_id: params.dailyPlanId,
			scene_id: params.sceneId,
			comment: params.comment,
			comment_id: params.commentId != null ? params.commentId : null
		};
		return this.http.post(environment.apiUrl + "/daily_plan/edit_dp_scene_has_comments", queryParams).pipe(map((response: Response) => response));
	}

	deleteCommentService(commentId) {
		let queryParams = {
			comment_id: commentId
		};
		return this.http.post(environment.apiUrl + "/daily_plan/delete_dp_scene_has_comments", queryParams).pipe(map((response: Response) => response));
	}

	getAllLocations(prod_id, orden, incluir_desarrollo = true, filters) {
		let datosLocaciones = {
			production_id: prod_id,
			order: orden,
			incluir_desarrollo: incluir_desarrollo,
			locations_id: filters.nombre ? filters.nombre.length ? filters.nombre.join(',') : null : null,
			country_id: filters.paisId,
			city_id: filters.ciudadId,
			chapter_from: filters.cap_desde,
			chapter_to: filters.cap_hasta,
			location_studio_ids: filters.loc_est, 
			day_night_ids: filters.dia_noche,
			inside_outside_ids: filters.int_ext
		};
		return this.http.post(environment.apiUrl + "/location/get_all_location_production", datosLocaciones).pipe(map((response: Response) => response));
	}

	unificarLoc(loc, loc_to_unify) {
		let unificar = {
			location: loc,
			location_to_unify: loc_to_unify,
		};
		return this.http.post(environment.apiUrl + "/location/unify_location", unificar).pipe(map((response: Response) => response));
	}

	eliminarServicio(id) {
		let params = {
			service_id: id,
		};
		return this.http.post(environment.apiUrl + "/daily_plan/delete_service", params).pipe(map((response: Response) => response));
	}

	get statusProductionEdit(): BehaviorSubject<boolean> {
		return this.$statusProductionEdit;
	}

	updateStatusProductionEdit(production: any) {
		if (production.prod_status_id != 4) 
			this.$statusProductionEdit.next(true);
		else 
			this.$statusProductionEdit.next(false);
	}

	editScenesMult(values, ids) {
		let params = {
			scenes_id: ids,
			year: values.year,
			day_history: values.day_history
		};
		return this.http.post(environment.apiUrl + "/scene/edit_scenes_year", params).pipe(map((response: Response) => response));
	}

	omittedSceneTentative(day, bloquear) {
		let params = {
			ts_has_unit_id: day,
			has_errors: bloquear
		};
		return this.http.post(environment.apiUrl + "/daily_plan/omitted_scenes_in_tentative", params).pipe(map((response: Response) => response));
	}

	changeStatusProduction(proj_id, status) {
		let params = {
			production_id: proj_id,
			prod_status_id: status
		};
		return this.http.post(environment.apiUrl + "/production/change_prod_status", params).pipe(map((response: Response) => response));
	}

	approveDesapproveAll(planId, approve: boolean) {
		let url;
		let params = {
			daily_plan_id: planId,
		};
		if (approve) url = "/daily_plan/approved_services"
		else url = "/daily_plan/non_approved_services"  
	
		return this.http.post(environment.apiUrl + url, params).pipe(map((response: Response) => response));
	}

	approveDesapproveGyc(serviceId, approve) {
		let url;
		let params = {
			service_id: serviceId,
		};
		if (approve) url = "/daily_plan/approved_services"
		else url = "/daily_plan/non_approved_services"  
	
		return this.http.post(environment.apiUrl + url, params).pipe(map((response: Response) => response));
	}

	cancelService(service_id) {
		let url;
		let params = {
			service_id: service_id,
		}; 
		return this.http.post(environment.apiUrl + "/daily_plan/cancel_service", params).pipe(map((response: Response) => response));
	}

	saveCancelObservation(service_id, comment, dp_service_observation_id?) {
		let url;
		let params = {
			service_id: service_id,
			observation: comment,
			dp_service_observation_id: dp_service_observation_id,
			is_refused: true
		}; 
		return this.http.post(environment.apiUrl + "/daily_plan/add_service_observations", params).pipe(map((response: Response) => response));
	}

	updateFieldService(values: any) {
		return this.http.post(environment.apiUrl + "/daily_plan/update_service", values).pipe(map((response: Response) => response));
	}

  getUnitsProduction(production_id) {
    let body = {
      production_id: production_id
    };
    return this.http.get(environment.apiUrl + "/production/get_units", { params: body }).pipe(map((response: Response) => response));
  }

  updateUnit(params: {
    unit_id: number,
    unit_name: string,
  }) {
    return this.http.post(environment.apiUrl + "/production/update_unit", params).pipe(map((response: Response) => response));
  }

	constructor(
		private http: HttpClient,
		private _dP: DatePipe,
		private _gS: GlobalService,
		private _fS: FilterService) { }
}
