import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { LanguageChangeService } from '../services/language-change.service';
import { GlobalService } from '../services/global.service';
import { LoginService } from '../services/login-service.service';
import { Router } from '@angular/router';


@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
	@Output() sidebar = new EventEmitter<string>();
	@Output() OnLogOut = new EventEmitter<boolean>();
	mostrarMenuLateral: any = this._gS.estadoSideBar;
	open_menu: boolean = false;
	loader: boolean = false;
	user: any;
	modulo: any;
	prodId: number = null;
	opcionSeleccionada: any;
	filtrarArgumentos: any = {
		search_text: ''
	}
	menuProductions = [
		{ name: 'parámetros', idAccess: 3, type: 'read', nameRoute: 'parametros', search: true },
		{ name: 'calendario', idAccess: 4, type: 'read', nameRoute: 'calendario', search: true },
		{ name: 'semanas', idAccess: 5, type: 'read', nameRoute: 'semanas', search: true },
		{ name: 'anticipos', idAccess: 25, type: 'read', nameRoute: 'anticipos', search: true },
		{ name: 'capítulos', idAccess: 7, type: 'read', nameRoute: 'capitulos', search: true },
		{ name: 'escenas', idAccess: 8, type: 'read', nameRoute: 'escenas', search: true },
		{ name: 'personajes', idAccess: 11, type: 'read', nameRoute: 'personajes', search: true },
		{ name: 'locaciones', idAccess: 11, type: 'read', nameRoute: 'locaciones', search: true },
		{ name: 'sets', idAccess: 12, type: 'read', nameRoute: 'sets', search: true },
		{ name: 'elementos', idAccess: 13, type: 'read', nameRoute: 'elementos', search: true },
		{ name: 'eventos', idAccess: 14, type: 'read', nameRoute: 'eventos', search: true },
		{ name: 'tentativos', idAccess: 15, type: 'read', nameRoute: 'tentativos', search: true },
		{ name: 'planes diarios', idAccess: 16, type: 'read', nameRoute: 'planes-diarios', search: true },
		{ name: 'crew', idAccess: 22, type: 'read', nameRoute: 'crew', search: true },
		{ name: 'dashboard', idAccess: 23, type: 'read', nameRoute: 'dashboard', search: true },
		{ name: 'continuidad', idAccess: 36, type: 'read', nameRoute: 'continuidad', search: true },
    { name: 'unidades', idAccess: 38, type: 'read', nameRoute: 'unidades', search: true },
		{ name: 'servicios', idAccess: 19, type: 'read', nameRoute: 'servicios', search: true },
		{ name: 'liquidaciones', idAccess: 35, type: 'read', nameRoute: 'liquidaciones', search: true },
	];

	estadoSidebar() {
		this.mostrarMenuLateral = !this.mostrarMenuLateral;
		this.sidebar.emit(this.mostrarMenuLateral);
	}

	changeLenguage(ev: string) {
		this._languageChangeService.changeLenguage(ev)
	}

	logOut() {
		this.OnLogOut.emit(true);
		setTimeout(_ => {
			this._lS.logOut();
		}, 500)
	}
	filterProductionsNav() {
		for (const option of this.menuProductions) {
			if (this.filtrarArgumentos.search_text) {
				option.name.search(this.filtrarArgumentos.search_text.toLowerCase()) != -1 ? option.search = true : option.search = false;
			} else {
				option.search = true
			}
		}
	}

	guardarPaginacion(hover) {
		localStorage.setItem('hover_submenu', JSON.stringify(hover));
		localStorage.setItem('pagination_ex', JSON.stringify(0));
		localStorage.setItem('array', JSON.stringify(20));
		this.estadoSidebar();
	}

	setearScroll() {
		localStorage.removeItem('scroll_tentativo_detail');
	}

	toggleMenu() {
		this.open_menu = !this.open_menu
	}

	obtenerModulo() {
		var pathModule = window.location.pathname;
		this.modulo = pathModule.split('/')[1];
		if (this.modulo == 'producciones') {
			if (parseInt(pathModule.split('/')[2])) {
				this.prodId = parseInt(pathModule.split('/')[2]);
			} else {
				this.opcionSeleccionada = 'producciones';
				this.prodId = null;
				return;
			}
		}

		this.opcionSeleccionada = JSON.parse(localStorage.getItem('hover_submenu'));
	}

	constructor(private router: Router, private _languageChangeService: LanguageChangeService, public _gS: GlobalService, private _lS: LoginService) { }

	ngOnInit() {
		this.router.events.subscribe((e) => {
			this.obtenerModulo();
		})

		this._languageChangeService.changeLenguage('esp');
		this.user = JSON.parse(sessionStorage.getItem('clap_access')).user;
		this.obtenerModulo();
	}

}
